<template>
  <Port />
</template>
<script>
  import Port from "@/components/Auth/Port/Port";
  export default {
    components: {
      Port,
    },
  };
</script>
